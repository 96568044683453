import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import './CommonerDashboard.css';

const CommonerDashboard = () => {
  const { currentUser } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [requestReason, setRequestReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleAdventurerRequest = async (e) => {
    e.preventDefault();
    
    if (!requestReason.trim()) {
      setError('Please provide a reason for your request.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      // API endpoint will be implemented in the auth server
      const response = await fetch('http://localhost:3001/api/request-role-upgrade', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          userId: currentUser.id,
          requestedRole: 'adventurer',
          reason: requestReason
        })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to send request');
      }
      
      setRequestSent(true);
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again later.');
      console.error('Error sending adventurer request:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="commoner-dashboard">
      <div className="welcome-section">
        <h2>Welcome to First Veil</h2>
        <p>
          You are currently a <span className="role-highlight">Commoner</span> in our realm. 
          Commoners have limited access to the world of First Veil.
        </p>
      </div>
      
      <div className="request-adventurer-section">
        <h3>Become an Adventurer</h3>
        <p>
          Adventurers gain access to exclusive content, including:
        </p>
        <ul className="benefits-list">
          <li>Full access to the First Veil website</li>
          <li>Image galleries of campaign moments</li>
          <li>Direct links to FoundryVTT for gameplay</li>
          <li>Communication channels with other players</li>
          <li>Game Master updates and session recaps</li>
        </ul>
        
        {requestSent ? (
          <div className="request-sent">
            <div className="request-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
            </div>
            <h4>Request Sent!</h4>
            <p>
              Your request to become an Adventurer has been sent to the Game Master.
              You will be notified once your request has been reviewed.
            </p>
          </div>
        ) : (
          <form className="request-form" onSubmit={handleAdventurerRequest}>
            <div className="form-group">
              <label htmlFor="requestReason">Why do you want to become an Adventurer?</label>
              <textarea
                id="requestReason"
                value={requestReason}
                onChange={(e) => setRequestReason(e.target.value)}
                placeholder="Tell us about yourself and why you want to join the adventure..."
                rows={5}
                required
              />
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <button 
              type="submit" 
              className="request-button"
              disabled={loading}
            >
              {loading ? 'Sending Request...' : 'Request Adventurer Status'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default CommonerDashboard; 