import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const chatEndRef = useRef(null);
  
  // Mock gallery images - will be replaced with actual gallery API
  const galleryImages = [
    { id: 1, url: 'https://via.placeholder.com/300x200/8B0000/FFFFFF?text=Campaign+Image+1', title: 'Battle at Crimson Keep' },
    { id: 2, url: 'https://via.placeholder.com/300x200/000000/FFFFFF?text=Campaign+Image+2', title: 'The Ancient Ruins' },
    { id: 3, url: 'https://via.placeholder.com/300x200/4A0000/FFFFFF?text=Campaign+Image+3', title: 'Meeting the Oracle' },
  ];
  
  // Mock chat messages - will be replaced with actual chat API
  useEffect(() => {
    // Simulating initial chat messages
    setChatMessages([
      { id: 1, sender: 'Game Master', content: 'Welcome to the party chat!', timestamp: new Date(Date.now() - 86400000).toISOString() },
      { id: 2, sender: 'Elyndra', content: 'Hello everyone! Ready for our next adventure?', timestamp: new Date(Date.now() - 3600000).toISOString() },
      { id: 3, sender: 'Thorgar', content: 'I found a new magical axe last session!', timestamp: new Date(Date.now() - 1800000).toISOString() },
    ]);
  }, []);
  
  // Scroll to bottom of chat when new messages arrive
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);
  
  const handleSendMessage = (e) => {
    e.preventDefault();
    
    if (!newMessage.trim()) return;
    
    // Add new message to chat
    const newChatMessage = {
      id: chatMessages.length + 1,
      sender: currentUser.displayName,
      content: newMessage,
      timestamp: new Date().toISOString()
    };
    
    setChatMessages([...chatMessages, newChatMessage]);
    setNewMessage('');
    
    // In a real app, you would send this message to your backend
  };
  
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('Image too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Convert file to base64 for demo purposes
      // In production, you would upload to a server
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Image = reader.result;
        
        // In a real app, you would upload the image to your server
        // and get back a URL to the uploaded image
        
        // Update user profile with new avatar
        try {
          const response = await fetch('http://localhost:3001/api/profile', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({
              avatar: base64Image
            })
          });
          
          if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Failed to update avatar');
          }
          
          setAvatarUrl(base64Image);
        } catch (err) {
          setUploadError(err.message || 'Failed to update avatar');
          console.error('Error updating avatar:', err);
        }
      };
      
      reader.onerror = () => {
        setUploadError('Error reading file');
      };
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => setActiveTab('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
          onClick={() => setActiveTab('chat')}
        >
          Party Chat
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          Profile
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="gm-messages">
              <h3>Messages from the Game Master</h3>
              {messages.length > 0 ? (
                <div className="message-list">
                  {messages.map(message => (
                    <div key={message.id} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">{message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div className="message-content">{message.content}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-messages">No messages from the Game Master yet.</p>
              )}
            </div>
            
            <div className="last-session">
              <h3>Last Session Recap</h3>
              {lastSession ? (
                <div className="session-recap">{lastSession}</div>
              ) : (
                <p className="no-recap">No session recap available.</p>
              )}
            </div>
            
            <div className="quick-links">
              <h3>Quick Links</h3>
              <div className="links-grid">
                <a href="https://firstveil.com" target="_blank" rel="noopener noreferrer" className="quick-link website">
                  <span className="link-icon">🌐</span>
                  <span className="link-text">First Veil Website</span>
                </a>
                <a href="https://foundryvtt.com" target="_blank" rel="noopener noreferrer" className="quick-link foundry">
                  <span className="link-icon">🎲</span>
                  <span className="link-text">FoundryVTT</span>
                </a>
                <a href="https://discord.gg/firstveil" target="_blank" rel="noopener noreferrer" className="quick-link discord">
                  <span className="link-icon">💬</span>
                  <span className="link-text">Discord Channel</span>
                </a>
                <button onClick={() => setActiveTab('gallery')} className="quick-link gallery">
                  <span className="link-icon">🖼️</span>
                  <span className="link-text">Campaign Gallery</span>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            <h3>Campaign Gallery</h3>
            <div className="gallery-grid">
              {galleryImages.map(image => (
                <div key={image.id} className="gallery-item">
                  <img src={image.url} alt={image.title} />
                  <div className="image-title">{image.title}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'chat' && (
          <div className="chat-tab">
            <h3>Party Chat</h3>
            <div className="chat-container">
              <div className="chat-messages">
                {chatMessages.map(msg => (
                  <div 
                    key={msg.id} 
                    className={`chat-message ${msg.sender === currentUser.displayName ? 'own-message' : ''}`}
                  >
                    <div className="message-sender">{msg.sender}</div>
                    <div className="message-bubble">{msg.content}</div>
                    <div className="message-time">{formatDate(msg.timestamp)}</div>
                  </div>
                ))}
                <div ref={chatEndRef} />
              </div>
              
              <form className="chat-input" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type your message..."
                />
                <button type="submit" disabled={!newMessage.trim()}>Send</button>
              </form>
            </div>
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Adventurer Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt={currentUser.displayName} />
                ) : (
                  <div className="avatar-placeholder">
                    {currentUser.displayName.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Change Avatar</h4>
                <p>Upload a small image (max 2MB) to represent your character</p>
                
                <label className="upload-button">
                  {isUploading ? 'Uploading...' : 'Choose Image'}
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <span className="info-label">Display Name:</span>
                <span className="info-value">{currentUser.displayName}</span>
              </div>
              <div className="info-item">
                <span className="info-label">Email:</span>
                <span className="info-value">{currentUser.email}</span>
              </div>
              <div className="info-item">
                <span className="info-label">Role:</span>
                <span className="info-value role-adventurer">Adventurer</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 